import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"

import PageBackground from "../img/hero-image-short-dark.png"
import PlumbingImg from "../img/plumbing-stock.jpg"
import HvacImg from "../img/HVAC-stock.jpg"
import PaintingImg from "../img/Painting-stock.jpg"
import RoofImg from "../img/Roof-stock.jpg"

const Fragment = React.Fragment;

const Body = () => (
    <div className="container body-content">
      <div className="row">
        <div className="col">
          <div className="card bg-light" style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.3em" }}>
            <div className="card-body">
              <Link to="/contact/">Contact Us!!!</Link>
            </div>
          </div>
        </div>
      </div>

      <div id="main-page-line" className="row">
        <div className="col line-section-wrapper">
          <div className="line-section">
            <p>On call 24 hrs, there is no job too small</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <div className="card">
            <img src={HvacImg} className="card-img-top" alt="HVAC"/>
            <div className="card-body">
              <h3 className="card-title">HVAC</h3>
              <p className="card-text">Heating and air conditioner repair and installation</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <img
              src={PlumbingImg}
              className="card-img-top"
              alt="Plumbing"
            />
            <div className="card-body">
              <h3 className="card-title">Home Repairs</h3>
              <p className="card-text">Plumbing and electrical repairs</p>
            </div>
          </div>
        </div>
      </div>

      <div id="main-page-line" className="row">
        <div className="col line-section-wrapper">
          <div className="line-section">
            <p>
              We do not charge emergency hour rates, there is <strong>One Rate</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <div className="card">
            <img
              src={PaintingImg}
              className="card-img-top"
              alt="Painting" />
            <div className="card-body">
              <h3 className="card-title">Painting</h3>
              <p className="card-text">Interior/Exterior painting and pressure washing</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card">
            <img
              src={RoofImg}
              className="card-img-top"
              alt="Roofing" />
            <div className="card-body">
              <h3 className="card-title">Roofing</h3>
              <p className="card-text">Roof installation and repair</p>
            </div>
          </div>
        </div>
      </div>

      <div id="main-page-line" className="row">
        <div className="col line-section-wrapper">
          <div className="line-section">
            <p>Licensed and Insured</p>
          </div>
        </div>
      </div>

    </div>
)

export default () => (
  <Fragment>
    <Header pageType="site"
            pageTitle="A.O.M."
            pageSubTitle="Maintenance and Repair"
            headerImg={PageBackground} />
    <Body />
    <Footer />
  </Fragment>
)
